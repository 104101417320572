// Here you can add other styles
.ql-editor{
    min-height:400px !important;
}
ul.bar-progress {
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
    display: flex;
    text-align: center;
    width: 100%;
    max-width: 100%;
  }
  span.span-top {
    color: #333;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.28px;
  }
  span.span-bottom {
    color: #333;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.28px;
  }
  span.span-middele {
    width: 20px;
    height: 20px;
    background: #d7d7d7;
    border-radius: 100%;
    margin: 10px auto;
    z-index: 999;
  }
  ul.bar-progress li {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  ul.bar-progress li:after {
    content: "";
    width: 50%;
    position: absolute;
    top: 47%;
    background: #d7d7d7;
    height: 5px;
    left: 50%;
  }
  ul.bar-progress li:before {
    content: "";
    width: 50%;
    position: absolute;
    top: 47%;
    background: #d7d7d7;
    height: 5px;
    right: 50%;
  }
  ul.bar-progress li:first-child:before {
    width: 0;
  }
  ul.bar-progress li:last-child:after {
    width: 0;
  }
  ul.bar-progress li.active-line1::after,
  ul.bar-progress li.active-line1 span.span-middele {
    background: #51a34e;
  }
  ul.bar-progress li.active-line2::before,
  ul.bar-progress li.active-line2 span.span-middele,
  ul.bar-progress li.active-line22::after {
    background: #51a34e;
  }
  ul.bar-progress li.active-line3::before,
  ul.bar-progress li.active-line3 span.span-middele,
  ul.bar-progress li.active-line33::after {
    background: #51a34e;
  }
  ul.bar-progress li.active-line4::before,
  ul.bar-progress li.active-line4 span.span-middele {
    background: #51a34e;
  }
  ul.bar-progress.part4 li {
    max-width: 100%;
    width: 25%;
  }
  ul.bar-progress.part2 li {
    max-width: 100%;
    width: 50%;
  }
  li.active-line0 span.span-middele {
    background: #51a34e;
  }
  .statusList ul li:nth-child(5),
  .statusList ul li:nth-child(6) {
    display: none;
  }
  
  .orderTracking-inner {
    background: white;
    max-width: 600px;
    width: 100%;
    padding: 40px 20px;
    border-radius: 8px;
    position: relative;
  }
  .orderTracking-row {
    display: flex;
    width: 100%;
  }
  .orderTracking-12 {
    width: 100%;
  }
  .orderTracking-12 h1 {
    color: #2b3674;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: -0.44px;
  }
  .orderTracking-6 {
    width: 50%;
  }
  .orderTracking-6 {
    width: 50%;
    display: flex;
    flex-direction: column;
  }
  .item-row {
    gap: 20px;
  }
  .item-row .orderTracking-6 label {
    margin-bottom: 10px;
    display: block;
  }
  .item-row .orderTracking-6 select,
  .orderTracking-6 select {
    display: flex;
    height: 48px;
    padding: 13px 12px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    width: 100%;
    border: 1px solid #667080;
    margin-bottom: 16px;
    border-radius: 6px;
    background: #fff;
  }
  .orderTracking-6 input {
    display: flex;
    height: 48px;
    padding: 13px 12px;
    justify-content: center;
    align-items: center;
    width: 100%;
    align-self: stretch;
    border: 1px solid #667080;
    margin-bottom: 20px;
    border-radius: 6px;
    background: #fff;
  }
  a.tracking_close {
    position: absolute;
    top: 0;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
  }
  .big-width .modal-dialog {
    max-width: 610px;
  }
  .main-div {
    background: #f8f8f8;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  .orderTracking-6 label {
    color: var(--Primary, #667080);
    font-feature-settings: "calt" off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.28px;
    margin-bottom: 5px;
  }
  .orderTracking-row {
    display: flex;
    width: 100%;
    gap: 16px;
  }
  select:focus-visible,
  input:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
  }
  ul.selectProduct_track {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }